import React from 'react'
import { MdOutlineAttachMoney } from 'react-icons/md'
import { FaLongArrowAltRight } from 'react-icons/fa'

const CallToAction = ({ images, bgImages, phone, texts }) => {
  return (
    <div className='flex flex-col md:flex-row items-start md:items-center mt-10'>
      <div className='svgParrallelograBg bgColor1 w-[60%] h-[80px] md:h-[250px] -mr-20 md:-mr-[350px] relative z-10 flex flex-col justify-center pl-5 md:pl-20'>
        <p className='text-white font-bold'>Call Us Now</p>
        <h2 className='text-white text-[20px] md:text-[50px]'>
          <a href={`tel:+1${phone}`}>
            {phone}
          </a>
        </h2>
      </div>

      <div
        className=' bg-center bg-cover bg-no-repeat w-[100%] relative -top-20 md:top-0 before:absolute before:bg-[#02080eb3] before:w-full before:h-full svgParrallelograInverso'
        style={{
          backgroundImage: `url("${bgImages}")`
        }}
      >
        <div className={`relative text-white text-center md:text-start ${texts ? 'py-32 md:py-10' : 'py-24'} px-5 md:pl-[380px]`}>
          <h1 className='py-5'>Payments Accepted</h1>
          <p className='pb-10 font-bold text-[25px] pr-0 md:pr-20 text-white'>Click on the link below to make a payment, Thank you for your Business!</p>
          {
            texts ?
              <p className='text-white pb-10'>{texts}</p>
              : null
          }

          <div className='flex flex-col md:flex-row'>
            <a
              className={`boton three my-3 px-6 py-4  rounded-lg bg-white text-[#257820] hover:text-white`}
              href={`https://www.paypal.com/paypalme/YardScapingServices?country.x=US&locale.x=en_US`}
              target='_blank'
              rel="noreferrer"
            >
              <div className='flex items-center gap-1 pr-5'>
                <span>
                  <MdOutlineAttachMoney />
                </span>
                <span className='capitalize'>
                  Pay Here
                </span>
              </div>
              <span>
                <FaLongArrowAltRight />
              </span>
            </a>
            <img
              src={images}
              alt='payment method'
              className="w-full md:w-[40%] object-contain"
            />

          </div>
        </div>
      </div>
    </div>
  )
}

export default CallToAction